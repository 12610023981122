<!-- 通讯录管理 -->
<template>
  <div style="height:100%;">
    <div
      style="background: rgba(255,255,255,1);padding: 20px;"
      class="flex flex-justify-between flex-align-center"
    >
      <div style="font-size:18px;font-weight:bold;color:rgba(41,41,41,1);">
        授权日志
      </div>
    </div>
    <div
      class="block"
      style="padding: 10px 20px 0 20px;height: calc(100% - 206px);"
    >
      <div style="height: calc(100% - 60px);overflow-y: auto">
        <el-table :data="logList" style="width: 100%;height:100%" stripe>
          <el-table-column
            prop="mobilephone"
            label="授权号码"
          ></el-table-column>
          <el-table-column prop="adminName" label="授权人员"></el-table-column>
          <el-table-column prop="isAuthorize" label="状态">
            <template slot-scope="scope">
              {{ statusMap[scope.row.isAuthorize] }}
            </template>
          </el-table-column>
          <el-table-column prop="n_users" label="授权时效" width="400px">
            <template slot-scope="scope">
              {{ getAuthTime(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200px">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.isAuthorize === 0"
                @click="revoke(scope.row, '拒绝')"
                type="text"
                >拒绝</el-button
              >
              <el-button
                v-if="[1, 5].includes(scope.row.isAuthorize)"
                @click="revoke(scope.row, '撤销')"
                type="text"
                >撤销</el-button
              >
              <el-button
                v-if="[0, 7].includes(scope.row.isAuthorize)"
                @click="revoke(scope.row, '')"
                type="text"
                >授权</el-button
              >
              <el-button @click="showLog(scope.row)" type="text"
                >查看日志</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="wrap">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="pageSetting.page"
        :page-size="pageSetting.pagesize"
        :total="pageSetting.total"
        @current-change="pageChange"
      ></el-pagination>
    </div>
    <!-- 授权申请 -->
    <el-dialog
      :title="`${handleType}授权申请`"
      :visible.sync="dialogAuthVisible"
      width="30%"
      @closed="dialogAuthClosed"
    >
      <el-form
        ref="ruleForm"
        :rules="rules"
        :model="ruleForm"
        label-position="left"
        label-width="100px"
      >
        <el-form-item label="申请人员:">
          <span>{{ handleRow.adminName }}</span>
        </el-form-item>
        <el-form-item label="手机号:">
          <span>+86 {{ handleRow.mobilephone }}</span>
        </el-form-item>
        <el-form-item v-if="handleType!=='拒绝'" label="授权时间:" prop="date">
          <el-date-picker
            :disabled="isCancel"
            v-model="ruleForm.date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="default"
          @click="cancelAuth"
          v-if="handleType === '拒绝'"
          >拒绝</el-button
        >
        <el-button
          type="default"
          @click="revokeAuth"
          v-if="handleType === '撤销'"
          >撤销</el-button
        >
        <el-button type="primary" v-if="handleType === ''" :disabled="authing" @click="sureAuth"
          >授权</el-button
        >
      </div>
    </el-dialog>
    <!-- 日志 dialog -->
    <el-dialog :title="logTitle" :visible.sync="dialogLogVisible" width="50%">
      <el-table :data="gridData">
        <el-table-column property="operationTime" label="时间">
          <template slot-scope="scope">
            {{
              scope.row.operationTime &&
                scope.row.operationTime.replace("T", " ")
            }}
          </template>
        </el-table-column>
        <el-table-column
          property="type"
          label="操作"
          width="200"
        ></el-table-column>
        <el-table-column property="remark" label="备注"></el-table-column>
        <el-table-column property="createdBy" label="操作人"></el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="logPageSetting.page"
        @current-change="logPageChange"
        :page-size="logPageSetting.pagesize"
        :total="logPageSetting.total"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { authPagingOrg, updateAuthStatus, authOrgLog } from "@/service/mes";
import { getCompInfoById, setCompanyInfo } from "@/service/company";
export default {
  name: "AuthLog",
  components: {},
  data() {
    // 授权状态说明
    // "0": "等待授权中",
    // "1": "已发送随机密码短信",
    // "2": "已撤销授权",
    // "4": "已拒绝",
    // "7": "授权时间过期",
    // "5": "已授权"

    // 授权: !5 && !1
    // 拒绝:0
    // 撤销授权:1 | 5
    return {
      statusMap: {
        "0": "等待授权中",
        "1": "已发送随机密码短信",
        "2": "已撤销授权",
        "4": "已拒绝",
        "7": "授权时间过期",
        "5": "已授权"
      },
      rules: {
        date: [{ required: true, message: "请选择授权时间", trigger: "change" }]
      },
      logTitle: "",
      gridData: [],
      dialogLogVisible: false,
      handleRow: {}, //操作行数据
      comp: {},
      ruleForm: {
        date: []
      },
      authing:false,//是否正在授权
      dialogAuthVisible: false, //授权申请
      deleteDialogVisible: false,
      popVisible: false,
      popTitle: "",
      handleType: "",
      pageSetting: {
        pagesize: 10,
        page: 1,
        total: 1
      },
      logPageSetting: {
        page: 1,
        pagesize: 5,
        total: 100
      },
      logList: []
    };
  },
  watch: {},
  computed: {
    //是否是可以撤销状态
    isCancel() {
      return [1, 5].includes(this.handleRow.isAuthorize);
    }
  },
  created() {
    this.debounceRefreshItems = _.debounce(this.getMainList, 500);
  },
  async mounted() {
    this.comp = await getCompInfoById(this.$store.state.loginUser.comp.id);
    console.log("公司信息", this.comp);
    this.getMainList();
  },
  methods: {
    //查看日志
    async showLog(row) {
      this.logTitle = `${row.authorizeOrgName}授权操作日志`;
      this.handleRow = row;
      this.dialogLogVisible = true;
      this.getLogList();
    },
    async getLogList() {
      const param = {
        search: {
          aahId: this.handleRow.aahId,
          orgid: this.comp.id //公司id
        },
        pageIndex: this.logPageSetting.page, // 当前页码
        pageSize: this.logPageSetting.pagesize, // 每页条数
        noCount: false,
        orderBy: "",
        countSql: ""
      };
      const res = await authOrgLog(param);
      if (res.success) {
        this.gridData = res.content.rows;
        this.logPageSetting.total = res.content.total;
      } else {
        this.$message.warning(res.msg || res?.exception?.message);
      }
    },
    getAuthTime(row) {
      const start =
        row.authorizeStartTime && row.authorizeStartTime.replace("T", " ");
      const end =
        row.authorizeEndTime && row.authorizeEndTime.replace("T", " ");
      return `${start || ""} / ${end || ""}`;
    },
    pageChange(page) {
      this.getMainList();
    },
    logPageChange(page) {
      this.getLogList();
    },
    async getMainList() {
      const parma = {
        search: {
          orgid: this.comp.id //公司id
        },
        pageIndex: this.pageSetting.page, // 当前页码
        pageSize: this.pageSetting.pagesize, // 每页条数
        noCount: false,
        orderBy: "",
        countSql: ""
      };
      const res = await authPagingOrg(parma);
      if (res.success) {
        this.logList = res.content.rows;
        this.pageSetting.total = res.content.total;
      } else {
        this.$message.warning(res.msg || res?.exception?.message);
      }
      console.log("获得分页数据", res);
    },
    revoke(row, type = "") {
      if (row.authorizeStartTime && row.authorizeEndTime)
        this.ruleForm.date = [
          new Date(row.authorizeStartTime),
          new Date(row.authorizeEndTime)
        ];
      this.handleType = type;
      this.dialogAuthVisible = true;
      this.handleRow = row;
      console.log("撤销授权", row);
    },
    dialogAuthClosed(){
      this.authing = false;
    },
    eleUiDateFormat(date) {
      const dateFormat = time => {
        if (!time || typeof time === "string") return time;
        let y = time.getFullYear();
        let m = String(time.getMonth() + 1).padStart(2, "0");
        let d = String(time.getDate()).padStart(2, "0");
        let h = String(time.getHours()).padStart(2, "0");
        let mm = String(time.getMinutes()).padStart(2, "0");
        let s = String(time.getSeconds()).padStart(2, "0");
        return y + "-" + m + "-" + d + "T" + h + ":" + mm + ":" + s;
      };
      //判断是否是数组[start,end]
      if (date instanceof Array) {
        const res = date.map(d => dateFormat(d));
        return res;
      } else {
        return dateFormat(date);
      }
    },
    async cancelAuth() {
      this.updateAuthStatus(4, () => {
        this.$message.success("拒绝成功");
      });
    },
    async sureAuth() {
      this.authing = true;
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          this.updateAuthStatus(3, () => {
            this.$message.success("授权成功");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async revokeAuth() {
      this.updateAuthStatus(6, () => {
        this.$message.success("撤销成功");
      });
    },
    /**
     * @desc 更新授权状态值
     * @param {Number} 2 某用户撤销某公司授权申请, 3某公司同意某用户授权申请,4 某公司拒绝某用户授权申请，5 某公司修改某用户授权时间,6 某公司撤销某用户授权信息
     * @param {Function}  成功回调
     */
    async updateAuthStatus(type, successCb) {
      const [start, end] = this.eleUiDateFormat(this.ruleForm.date || []);
      const param = {
        id: this.handleRow.id, //授权管理分页查询出的id，即授权管理子表id
        aahId: this.handleRow.aahId, //授权管理分页查询出的aahId,即授权主表id
        isAuthorize: this.handleRow.isAuthorize, //当前状态
        authorizeOrgId: this.handleRow.authorizeOrgId, //公司id
        authorizeOrgName: this.handleRow.authorizeOrgName, //公司名称
        revokeRemark: this.handleRow.remark, //撤销原因
        authorizeStartTime: start, //开始时间
        authorizeEndTime: end, //结束时间
        type
      };
      const res = await updateAuthStatus(param);
      if (res.success) {
        successCb();
        this.dialogAuthVisible = false;
        this.getMainList();
      } else {
        this.$message.warning(res.msg || res?.exception?.message);
      }
    }
  }
};
</script>
<style scoped>
div >>> th {
  color: #4d4d4d;
  font-size: 16px;
}
</style>
<style scoped src="../comp/style.css"></style>
<style scoped lang="less">
.block {
  color: rgba(41, 41, 41, 1);
  background: rgba(255, 255, 255, 1);
  overflow: auto;
  margin: 30px;
  padding: 20px;
}

.input-block {
  .el-input {
    width: 270px;
  }
  .el-button {
    width: 76px;
    left: -4px;
    position: relative;
    z-index: 2;
  }
}

.custom-tree-node {
  font-size: 14px;
  min-width: 200px;
}

.pop-menu > div {
  padding: 10px;
  cursor: pointer;
}

.pop-menu > div:hover {
  background: #eee;
}

.pop-menu > div > span {
  margin-right: 10px;
}
.wrap {
  margin: 30px;
}

.el-checkbox-group {
  flex-direction: column;
  display: flex;
}
.dialog-content {
  font-size: 16px;
  color: #292929;
  padding: 15px 0 40px;
  text-align: center;
  border-bottom: 1px solid #e6e6e6;
}
</style>
